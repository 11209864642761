"use client";

import { Button, Divider, Modal } from "@mantine/core";
import { IconBrandWhatsapp } from "@tabler/icons-react";
// import flags from "react-phone-number-input/flags";
import "react-phone-number-input/style.css";

import config from "@/config";

import ContactForm from "../ContactForm/ContactForm";

interface ModalStudieProps {
  opened: boolean;
  close: () => void;
  selectedStudie: any;
}

export default function ModalStudie({
  opened,
  close,
  selectedStudie,
}: ModalStudieProps) {
  return (
    (<Modal.Root
      opened={opened}
      onClose={close}
      size="md"
      centered
      // className="py-"
      classNames={{
        root: "rtl text-right",
        content:
          "bg-Color1Lighten60 text-Color3 px-[0.3rem] pb-[1rem] text-right rtl",
        header: "bg-Color1Lighten60 text-Color3",
        inner: "",
        overlay: "",
      }}
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>{"طلب دراسه: " + selectedStudie}</Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <div className="StudieForm">
            <ContactForm
              size="sm"
              type={decodeURIComponent(selectedStudie).replace(/-/g, " ")}
              row={2}
            />
            <Divider
              label="او يمكنك التواصل معنا علي الواتساب"
              labelPosition="center"
              my="lg"
            />
            <Button
              variant="filled"
              color="green"
              fullWidth
              mt="lg"
              component="a"
              leftSection={<IconBrandWhatsapp size={20} />}
              href={`https://api.whatsapp.com/send/?phone=${config.WTSAPP_NUM}&text=اود الحصول علي دراسه: ${selectedStudie}&type=phone_number&app_absent=0`}
              target="_blank"
            >
              التواصل معنا عبر الواتساب
            </Button>
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>)
  );
}
