"use client";

import { useState } from "react";

import {
  Button,
  Center,
  Loader,
  SegmentedControl,
  Select,
  Space,
  Text,
  TextInput,
  Textarea,
  VisuallyHidden,
  rem,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  IconBrandTelegram,
  IconBrandWhatsapp,
  IconMail,
  IconMailCheck,
  IconMailExclamation,
  IconPhoneCall,
  IconSend,
} from "@tabler/icons-react";
import axios from "axios";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
// import flags from "react-phone-number-input/flags";
import ar from "react-phone-number-input/locale/ar";
import "react-phone-number-input/style.css";

export default function ContactForm({
  size = "lg",
  type = null,
  row = 2,
}: {
  size: string;
  type: string | null;
  row: number;
}) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const form = useForm({
    mode: "controlled",

    // validateInputOnChange: true,
    validateInputOnBlur: true,
    initialValues: {
      name: "",
      phone: "",
      email: "",
      studie: type ? type : "طلب دراسة جدوى",
      notes: "",
      howtocontact: "Whatsapp",
    },
    validate: {
      name: (value) =>
        value.length < 2
          ? "يجب كتابة اسمك علي الاقل حرفين!"
          : /^[\p{Letter}\s\-.']+$/u.test(value)
            ? null
            : "يجب كتابة الاسم بدون ارقام!",
      email: (value) =>
        value.length <= 2 ? "يجب كتابة البريد الالكتروني!" : null,
      phone: (value) =>
        value && value.length < 8
          ? "رقم الهاتف يجب أن يكون على الأقل 8 أرقام!"
          : value && isPossiblePhoneNumber(value)
            ? null
            : "الرقم غير صحيح تاكد مره أخري",
      studie: (value) => (value.length <= 0 ? "برجاء اختيار خدمة!" : null),
    },
  });

  const handleSubmit = async (values: any) => {
    setLoading(true);
    try {
      const sendmail = await axios
        .post("/api/sendmail", values)
        .then((res) => res);
      setTimeout(() => {
        if (sendmail.status === 200) {
          setLoading(false);
          setSuccess(true);
        } else {
          setLoading(false);
          setError(true);
        }
      }, 0);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  return (
    <>
      {error ? (
        <>
          <Center
            w="100%"
            h="100%"
            className="flex min-h-[300px] flex-col items-center justify-center"
          >
            <IconMailExclamation size={40} color="red" />
            <Text size="lg" fw={700}>
              {error}
              هناك خطأ ما في الارسال
            </Text>
          </Center>
          <Center>
            <Button
              variant="filled"
              color="Color3"
              onClick={() => {
                setError(false);
                form.reset();
              }}
            >
              إعادة المحاولة
            </Button>
          </Center>
        </>
      ) : success ? (
        <>
          <Center
            w="100%"
            h="100%"
            className="flex min-h-[400px] flex-col items-center justify-center"
          >
            <IconMailCheck size={40} color="green" />
            <Text size="lg" fw={700}>
              تم ارسال الطلب بنجاح
            </Text>
          </Center>
          <Center>
            <Button
              variant="filled"
              color="Color3"
              onClick={() => {
                setSuccess(false);
                form.reset();
              }}
            >
              أرسال مره أخري
            </Button>
          </Center>
        </>
      ) : loading ? (
        <Center
          className="flex min-h-[200px] flex-col items-center justify-center sm:min-h-[200px] md:min-h-[500px] lg:min-h-[500px]"
          w="100%"
          h="100%"
        >
          <div className="flex w-[100%] items-center justify-center pb-10 text-Color3">
            <IconSend size={40} />
          </div>

          <Text size="lg" color="Color3" fw={700}>
            جاري الارسال ...
          </Text>
          <Space h="1rem" />
          <Loader size="md" color={"Color4"} />
        </Center>
      ) : (
        <form dir="rtl" onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            className="mb-4"
            data-autofocus
            label="الاسم"
            required
            placeholder="الاسم بالكامل"
            size={size}
            autoComplete="off"
            key={form.key("name")}
            {...form.getInputProps("name")}
          />
          <TextInput
            className="mb-4"
            label="البريد الالكتروني"
            placeholder="email@provider.com"
            required
            size={size}
            // type="email"
            key={form.key("email")}
            {...form.getInputProps("email")}
          />
          {/* <div className=""> */}
          <TextInput
            className="mb-4 !p-0"
            label="الهاتف/ واتس اب"
            dir="ltr"
            placeholder="59 079 6591"
            required
            size={size}
            type="tel"
            component={PhoneInput}
            // @ts-ignore: Unreachable code error
            defaultCountry="SA"
            classNames={{
              input:
                "!pe-0 focus:!outline-0 focus-visible:!outline-0 !text-left focus:!text-left",
            }}
            // flags={flags}
            labels={ar}
            name="phone"
            withCountryCallingCode={true}
            key={form.key("phone")}
            {...form.getInputProps("phone")}
          />
          {/* </div> */}
          <Text size="sm" className="mb-2">
            التواصل عبر
          </Text>
          <SegmentedControl
            fullWidth
            size="sm"
            radius="xl"
            color="Color3"
            withItemsBorders={true}
            transitionTimingFunction="linear"
            defaultValue="Whatsapp"
            data={[
              {
                value: "Whatsapp",
                label: (
                  <Center style={{ gap: 10 }} title="واتس اب">
                    <IconBrandWhatsapp
                      style={{
                        width: rem(16),
                        height: rem(16),
                        color: "Color4",
                        background: "Color4",
                      }}
                    />
                    <span className="xxs:hidden sm:block md:block lg:block">
                      واتس اب
                    </span>
                    <VisuallyHidden>واتس اب</VisuallyHidden>
                  </Center>
                ),
              },

              {
                value: "Email",
                label: (
                  <Center style={{ gap: 10 }} title="البريد">
                    <IconMail style={{ width: rem(16), height: rem(16) }} />
                    <span className="xxs:hidden sm:block md:block lg:block">
                      البريد
                    </span>
                    <VisuallyHidden>البريد</VisuallyHidden>
                  </Center>
                ),
              },
              {
                value: "PhoneCall",
                label: (
                  <Center style={{ gap: 10 }} title="الهاتف">
                    <IconPhoneCall
                      style={{ width: rem(16), height: rem(16) }}
                    />
                    <span className="xxs:hidden sm:block md:block lg:block">
                      الهاتف
                    </span>
                    <VisuallyHidden>الهاتف</VisuallyHidden>
                  </Center>
                ),
              },
              {
                value: "Telegram",
                label: (
                  <Center style={{ gap: 10 }} title="تليجرام">
                    <IconBrandTelegram
                      style={{ width: rem(16), height: rem(16) }}
                    />
                    <span className="xxs:hidden sm:block md:block lg:block">
                      تليجرام
                    </span>
                    <VisuallyHidden>تليجرام</VisuallyHidden>
                  </Center>
                ),
              },
            ]}
            key={form.key("howtocontact")}
            {...form.getInputProps("howtocontact")}
          />

          <div className="mt-1">
            <Select
              label="خدمتنا"
              placeholder="برجاء اختيار خدمة!"
              data={
                type
                  ? [
                      type,
                      "طلب دراسة جدوى",
                      "تأسيس الشركات",
                      "أبحث السوق",
                      "خطوط الإنتاج",
                      "دراسة المخاطر",
                      "الهيكل التنظيمي للمشروعات",
                      "العروض التقديمية",
                    ]
                  : [
                      "طلب دراسة جدوى",
                      "تأسيس الشركات",
                      "أبحث السوق",
                      "خطوط الإنتاج",
                      "دراسة المخاطر",
                      "الهيكل التنظيمي للمشروعات",
                      "العروض التقديمية",
                    ]
              }
              defaultValue={type ? type : "طلب دراسة جدوى"}
              searchable
              checkIconPosition="left"
              allowDeselect={false}
              required
              size={size}
              maxDropdownHeight={200}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
                shadow: "md",
              }}
              key={form.key("studie")}
              {...form.getInputProps("studie")}
            />
          </div>

          <div className="mt-4">
            <Textarea
              className="mb-4"
              placeholder="ان كان لديك اي ملاحظات برجاء ادراجها هنا ..."
              label="رسالتك"
              size={size}
              autosize
              minRows={row}
              maxRows={5}
              key={form.key("notes")}
              {...form.getInputProps("notes")}
            />
          </div>
          <div>
            <Button
              variant="filled"
              color="Color4"
              type="submit"
              fullWidth
              aria-label="ارسال الطلب"
              mt={size}
              leftSection={<IconMail size={20} />}
            >
              ارسال
            </Button>
          </div>
        </form>
      )}
    </>
  );
}
